<template>
    <div id="user-box">
        <div>
            <!-- 查询条件 -->
            <el-form :inline="true" :model="search" class="demo-form-inline" style="text-align:center;">
                <el-form-item label="姓名:">
                    <el-input v-model="search.name" placeholder="姓名"></el-input>
                </el-form-item>
                <el-form-item label="电话:">
                    <el-input v-model="search.phone" placeholder="电话"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="ToSearch">查询</el-button>
                </el-form-item>
            </el-form>

            <!-- 用户列表 -->
            <el-table :data="yuyueList" :cell-style="{ padding: '2px 0' }" height="634" border stripe>
                <el-table-column label="就诊状态" width="100" align="center">
                    <template slot-scope="scope">
                        <el-button style="color:green; " v-if="scope.row.use_state == 1" @click="ToHeXiao(scope.row)"
                            type="text">已就诊</el-button>
                        <el-button style="color:red;" v-if="scope.row.use_state == 2" type="mini">未就诊</el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="hz_name" label="患者姓名" align="center" width="120"></el-table-column>
                <el-table-column prop="hz_phone" label="患者电话" align="center" width="120"></el-table-column>
                <el-table-column prop="hz_sex" label="患者性别" align="center" width="80"></el-table-column>
                <el-table-column prop="yuyue_date" label="预约时间" align="center" width="180"></el-table-column>
                <el-table-column label="挂号金额" width="100" align="center">
                    <template slot-scope="scope">{{ scope.row.price / 100 }}元</template>
                </el-table-column>
                <el-table-column prop="hz_desc" label="病情描述" align="center" width="180"></el-table-column>
                <el-table-column prop="name" label="挂号医生" align="center" width="180"></el-table-column>
                <el-table-column label="付款状态" width="100" align="center">
                    <template slot-scope="scope">
                        <el-button style="color:green;" v-if="scope.row.pay_state == 1" type="text">已付款</el-button>
                        <el-button style="color:red;" v-if="scope.row.pay_state == 2" type="text">未付款</el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="pay_time" label="付款时间" align="center" width="180"></el-table-column>
                <el-table-column prop="use_time" label="核销时间" align="center" width="180"></el-table-column>
            </el-table>
            <!-- 页码 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="page.CurrPage" :page-sizes="[10, 15, 20, 25]" :page-size="page.PageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="page.DataCount" :sizes='page.PageCount'>
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { getForm, postForm } from "../tools/tool"

export default {
    name: "UserView",
    data() {
        return {
            search: {
                CurrPage: 1,
                PageSize: 10,
            },
            yuyueList: [],
            page: {
                CurrPage: 1, //初始页
                PageSize: 10, // 每页的数据
                DataCount: 0, // 总条数
                PageCount: 0, // 总页数
            },
            remark: "",
        }
    },

    created() {
        this.onSubmit()
    },
    mounted() { },

    methods: {
        ToHeXiao(item) {
            let orderNo = item.order_no
            let useState = item.use_state
            if (useState == 2) { useState = 1 }
            postForm("/tohexiao", { order_no: orderNo, use_state: useState })
                .then(data => {
                    item.use_state = useState
                    alert(data.msg)
                })
                .catch(err => { alert(err) })
        },
        ToSearch() {
            this.onSubmit();
        },
        onSubmit() {
            getForm('/yuyue/list', this.search)
                .then(data => {
                    this.yuyueList = data.data
                    this.page = data.page
                    console.log('yuyuelist:', this.yuyueList)
                }).catch(err => {
                    alert(err)
                })
        },
        onRemark(yuyueId) {
            console.log("yuyueId: ", yuyueId, remark);
            if (yuyueId == "" || yuyueId == 0 || !yuyueId || this.remark == "") {
                alert("参数有误！")
                return
            }
            postForm("/yuyue/remark", {
                id: yuyueId,
                remark: this.remark,
            }).then(data => {
                // 清空备注栏
                this.remark = ""
                // 刷新数据
                this.onSubmit()
                alert(data.msg)
            }).catch(err => {
                alert(err)
            })

        },
        // 初始页current_page、初始每页数据数page_size和数据data
        handleSizeChange: function (size) {
            this.search.PageSize = size;
            this.onSubmit()
        },
        handleCurrentChange: function (CurrPage) {
            this.search.CurrPage = CurrPage;
            console.log("CurrPage: ", this.search.CurrPage)
            console.log(this.search.CurrPage) //点击第几页
            Submit()
        },
    }
}

</script>

<style scoped lang="less">
#user-box {
    display: flex;
}

#remark {
    margin-top: 80px;
    margin-left: 50px;
}
</style>
